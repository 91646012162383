@media (min-width: 2901px) {
  html{
    font-size: 14.48px;
  }
}
@media (max-width: 2900px) {
  html {
    font-size: 12.76px;
  }
}
@media (max-width: 1900px) {
  html {
    font-size: 10px;
  }
}
@media (max-width: 1550px) {
  html {
    font-size: 7.48px;
  }
}
@media (max-width: 1299px) {
  html {
    font-size: 6.48px;
  }
}

@media (max-width: 1099px) {
  html {
    font-size: 6.32px;
  }
}


@media (max-width: 900px) {
  html {
    font-size: 5.52px;
  }
}

@media (max-width: 760px) {
  html {
    font-size: 4.72px;
  }
}

/* 
@media (max-width: 650px) {
  html {
    font-size: 4.08px;
  }
}

@media (max-width: 540px) {
  html {
    font-size: 3.52px; 
  }
}

@media (max-width: 480px) {
  html {
    font-size: 2.32px;
  }
}
@media (max-width: 320px) {
  html {
    font-size: 1.76px;
  }
}
@media (max-width: 250px) {
  html {
    font-size: 1.5px;
  }
}
@media (max-width: 210px) {
  html {
    font-size: 1.3px;
  }
}
@media (max-width: 180px) {
  html {
    font-size: 1px;
  }
}
@media (max-width: 140px) {
  html {
    font-size: .8px;
  }
}
@media (max-width: 100px) {
  html {
    font-size: .6px;
  }
} */