.services_img{
    width: 50rem;
    margin-left: 10rem;
    margin-right: 10rem;
}

.width_200{
    width: 200% !important;
}
.width_61{
    width: 61% !important;
}
@media  (max-width: 760px) {
    .first_span{
        font-size: 24px !important;
    }
    .services_img{
        margin-left: 10rem;
        margin-right: 10rem;
    }
    .width_200{
        width: 100% !important;
    }
    .width_61{
        width: 100% !important;
    }
}
@media  (max-width: 480px) {
    .section1 .sectionData img{
        width: 17rem;
    }
}