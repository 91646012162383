.servicesTabs{
    width: 100%;
    margin-top: 8.0rem;
}

.porfoIdeasSection{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
}
.btn1.btn2{
    padding: 10px 20px !important;
    height: auto !important;
    width: auto !important;
}

@media screen and (max-width:768px){
   
}