.form_div{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10rem;
    margin-bottom: 5rem;
}
.contactForm{
    width: 50%;
}
.sub_btn_div{
    display: flex;
    justify-content: center;
}
.w_200{
    width: 200%;
}
.contactForm .sub_btn{
    background-color: #FF4137;
    border-color: #FF4137;
    display: block;
    padding: 1rem 2rem;
    border-radius: 60px;
    font-size: 3rem;
}

.contactForm .field{
    background: transparent;
    border-radius: 3.4rem;
    color: white;
    padding: 2rem 2rem;
    font-size: 2rem;
    border-color: rgb(148, 147, 147);
}
.contactForm .field:not(.name){
    margin: 3rem 0rem;
}
.contactForm .textArea{
    padding: 2rem;
    height: 27rem;
}
.contactForm .field::placeholder
{
    color: white!important; /* Change the placeholder color to gray */
}

@media screen and (max-width:768px) {
    .w-200{
        width: 100%;
    }
    .contactForm{
        width: 100%;
    }
    .contactForm .field{
        background: transparent;
        border-radius: 3.4rem;
        color: white;
        padding: 3rem 2rem;
        font-size: 2rem;
        border-color: rgb(148, 147, 147);
        font-size: 14px;
    }
}