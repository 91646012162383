.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}
.logo{
    width: 32.8rem;
    height: 7rem;
}
.headLinks .link{
    color: white;
}
.headLinks .link:hover{
    color: #FF4137;
}
.header ul{
    display: flex;
}
.header ul li{
    list-style: none;
    display: inline-flex;
    margin: 0px 1rem;
    height: 1.8rem;
    padding: 0rem 2rem;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.list-icon{
    font-size: 24px;
    display: none;
}

.sidebar1 {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    /* background-color: white !important; */
    height: 100vh;
    z-index: 1000;
    padding: 40px 50px ;
    font-size: 18px;
    display: none;
    flex-direction: column;
    gap: 50px;

}
.sidebar1 span{

    /* background-color: white !important; */
}
.sidebar1 span a{
    color: white !important;
    /* background-color: white !important; */
}

@media (max-width:768px){
    .sidebar1{
        display: flex;
        
    }
    .sidebar1 span a{
        display: block;
    }
    .header ul li {
        display: none;
    }
    .list-icon{
        display: block;
    }
}
/* @media (max-width: 480px) {
    .header ul li {
        padding: 0rem 1.8rem;
        margin: 0px 0.6rem;
        font-size: 2.3rem;
    }
}
@media (max-width: 320px) {
    .menu .search,
    .menu .hamburg {
        width: 4.3rem;
        height: 4.3rem;
        margin: 0rem 2rem;
    }
} */