@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;
@tailwind variants;

*{
    background-color: #1A2430;
    color: #FFFFFF;
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
}
.body_margin{
    overflow-x: hidden;
    overflow-y: hidden;
    margin: 4rem 8rem 0rem 8rem;
    min-height: 77vh;
}
.mtb0{
    margin-top: 0px;
    margin-bottom: 0px;
}
.mlr0{
    margin-left: 0px;
    margin-right: 0px;
}
.red{
    color: #FF4137;
}

.skewContainer {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 0;
    height: fit-content;
    box-sizing: border-box;
    margin-right: 1rem;
  }
  
  .skew {
    position: absolute;
    border: 1px solid white;
    top: -3.0rem;
    left: 4.0rem;
    width: 32.3rem;
    height: 22.1rem;
    background: transparent;
    transform: skewX(-20deg);
  }
  
  .skewImg {
    width: 60rem;
    transform: skewX(0deg);
    background: none;
    z-index: 0;
  }
  
  .skew1 {
    position: absolute;
    bottom: -2.6rem;
    right: 4.0rem;
    width: 32.3rem;
    height: 22.1rem;
    background-color: #364854;
    transform: skewX(-20deg);
    z-index: -1;
  }

  @media screen and (max-width:786px) {
    
   
    .body_margin{
      /* overflow-x: hidden;
      overflow-y: hidden; */
      margin: 4rem 2rem 0rem 2rem;
      /* min-height: 77vh; */
    }
  }